require('./bootstrap');

window._ = require("lodash");
import "bootstrap";

//TyppyJs CSS
//import { animateFill } from 'tippy.js';   // Viene importato in bootstrap.js
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/animations/scale.css';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/themes/material.css';

// Tiny Slider
import 'tiny-slider/dist/tiny-slider.css';
import { tns } from "tiny-slider/src/tiny-slider";
window.tns = tns;

// Masonry
import 'masonry-layout';
